import React from 'react';
import CatReal1 from '../Images/Cats/cat8.jpeg';
import CatPainting1 from '../Images/Cats/cat1.jpeg';
import CatReal2 from '../Images/Cats/cat15.jpeg';
import CatPainting2 from '../Images/Cats/cat11.jpeg';
import CatReal3 from '../Images/Cats/cat14.jpeg';
import CatPainting3 from '../Images/Cats/cat10.jpeg';
import DogReal1 from '../Images/Dogs/dog59.jpeg';
import DogPainting1 from '../Images/Dogs/dog39.jpeg';
import DogReal2 from '../Images/Dogs/dog60.jpeg';
import DogPainting2 from '../Images/Dogs/dog36.jpeg';
import DogReal3 from '../Images/Dogs/dog61.jpeg';
import DogPainting3 from '../Images/Dogs/dog30.jpeg';
import HorseReal1 from '../Images/Horses/horse2.jpeg';
import HorsePainting1 from '../Images/Horses/horse1.jpeg';
import HorseReal2 from '../Images/Horses/horse5.jpeg';
import HorsePainting2 from '../Images/Horses/horse4.jpeg';
import HorseReal3 from '../Images/Horses/horse6.jpeg';
import HorsePainting3 from '../Images/Horses/horse3.jpeg';
import BuildingReal1 from '../Images/Buildings/building14.jpeg';
import BuildingPainting1 from '../Images/Buildings/building8.jpeg';
import BuildingReal2 from '../Images/Buildings/building15.jpeg';
import BuildingPainting2 from '../Images/Buildings/building12.jpeg';
import BuildingReal3 from '../Images/Buildings/building16.jpeg';
import BuildingPainting3 from '../Images/Buildings/building9.jpeg';

class PaintingSection extends React.Component {
    render(){
        return(
            <div>
                <div className="visible-xs" style={{overflowX:'hidden'}}>
                    {/* real/painting dogs */}
                    <div></div>
                    <div>
                        <h1>Dog Portraits</h1>
                        <a style={{textDecoration:'none',color:'black',fontSize:'25px'}} href="/dogs"><button id="btn-primary">Click to See Dogs</button></a>
                        <br/><br/><br/>
                    </div>
                    <div>
                        <div style={{float:'right',width:'50%'}}>
                            <img alt={'dog'} style={{width:'175px'}} src={DogPainting1}/>
                            <br/><br/>
                            <img alt={'dog'} style={{width:'175px'}} src={DogPainting2}/>
                            <br/><br/>
                            <img alt={'dog'} style={{width:'175px'}} src={DogPainting3}/>
                            <br/><br/><br/><br/>
                        </div>
                        <div style={{float:'left',width:'50%'}}>
                            <img alt={'dog'} style={{width:'175px'}} src={DogReal1}/>
                            <br/><br/>
                            <img alt={'dog'} style={{width:'175px'}} src={DogReal2}/>
                            <br/><br/>
                            <img alt={'dog'} style={{width:'175px'}} src={DogReal3}/>
                            <br/><br/><br/><br/>
                        </div>
                    </div>

                    {/* real/painting horses */}
                    <div>
                        <h1>Horse Portraits</h1>
                        <a style={{textDecoration:'none',color:'black',fontSize:'25px'}} href="/horses"><button id="btn-primary">Click to See Horses</button></a>
                        <br/><br/><br/>
                    </div>
                    <div>
                        <div style={{float:'right',width:'50%'}}>
                            <img alt={'horse'} style={{width:'175px'}} src={HorsePainting1}/>
                            <br/><br/>
                            <img alt={'horse'} style={{width:'175px'}} src={HorsePainting2}/>
                            <br/><br/>
                            <img alt={'horse'} style={{width:'175px'}} src={HorsePainting3}/>
                            <br/><br/><br/>
                        </div>
                        <div style={{float:'left',width:'50%'}}>
                            <img alt={'horse'} style={{width:'175px'}} src={HorseReal1}/>
                            <br/><br/>
                            <img alt={'horse'} style={{width:'175px'}} src={HorseReal2}/>
                            <br/><br/>
                            <img alt={'horse'} style={{width:'175px'}} src={HorseReal3}/>
                            <br/><br/><br/>
                        </div>
                    </div>

                    {/* real/painting buildings */}
                    <div>
                        <h1>Building Portraits</h1>
                        <a style={{textDecoration:'none',color:'black',fontSize:'25px'}} href="/buildings"><button id="btn-primary">Click to See Buildings</button></a>
                        <br/><br/><br/>
                    </div>
                    <div>
                        <div style={{float:'right',width:'50%'}}>
                            <img alt={'building'} style={{width:'175px'}} src={BuildingPainting1}/>
                            <br/><br/>
                            <img alt={'building'} style={{width:'175px'}} src={BuildingPainting2}/>
                            <br/><br/>
                            <img alt={'building'} style={{width:'175px'}} src={BuildingPainting3}/>
                            <br/><br/><br/>
                        </div>
                        <div style={{float:'left',width:'50%'}}>
                            <img alt={'building'} style={{width:'175px'}} src={BuildingReal1}/>
                            <br/><br/>
                            <img alt={'building'} style={{width:'175px'}} src={BuildingReal2}/>
                            <br/><br/>
                            <img alt={'building'} style={{width:'175px'}} src={BuildingReal3}/>
                            <br/><br/><br/>
                        </div>
                    </div>

                    {/* real/painting cats */}
                    <div>
                        <h1>Cat Portraits</h1>
                        <a style={{textDecoration:'none',color:'black',fontSize:'25px'}} href="/cats"><button id="btn-primary">Click to See Cats</button></a>
                        <br/><br/><br/>
                    </div>
                    <div>
                        <div style={{float:'right',width:'50%'}}>
                            <img alt={'cat'} style={{width:'175px'}} src={CatPainting3}/>
                            <br/><br/>
                            <img alt={'cat'} style={{width:'175px'}} src={CatPainting2}/>
                            <br/><br/>
                            <img alt={'cat'} style={{width:'175px'}} src={CatPainting1}/>
                            <br/><br/><br/>
                        </div>
                        <div style={{float:'left',width:'50%'}}>
                            <img alt={'cat'} style={{width:'175px'}} src={CatReal3}/>
                            <br/><br/>
                            <img alt={'cat'} style={{width:'175px'}} src={CatReal2}/>
                            <br/><br/>
                            <img alt={'cat'} style={{width:'175px'}} src={CatReal1}/>
                            <br/><br/><br/>
                        </div>
                    </div>
                </div>




                <div className="hidden-xs" style={{overflowX:'hidden'}}>
                    {/* real/painting dogs */}
                    <div></div>
                    <div>
                        <h1>Dog Portraits</h1>
                        <a style={{textDecoration:'none',color:'black',fontSize:'25px'}} href="/dogs"><button id="btn-primary">Click to See Dogs</button></a>
                        <br/><br/><br/>
                    </div>
                    <div>
                        <div style={{float:'right',width:'50%'}}>
                            <img alt={'dog'} style={{width:'100%',padding:'50px'}} src={DogPainting1}/>
                            <br/><br/>
                            <img alt={'dog'} style={{width:'100%',padding:'50px'}} src={DogPainting2}/>
                            <br/><br/>
                            <img alt={'dog'} style={{width:'100%',padding:'50px'}} src={DogPainting3}/>
                            <br/><br/><br/><br/>
                        </div>
                        <div style={{float:'left',width:'50%'}}>
                            <img alt={'dog'} style={{width:'100%',padding:'50px'}} src={DogReal1}/>
                            <br/><br/>
                            <img alt={'dog'} style={{width:'100%',padding:'50px'}} src={DogReal2}/>
                            <br/><br/>
                            <img alt={'dog'} style={{width:'100%',padding:'50px'}} src={DogReal3}/>
                            <br/><br/><br/><br/>
                        </div>
                    </div>

                    {/* real/painting horses */}
                    <div>
                        <h1>Horse Portraits</h1>
                        <a style={{textDecoration:'none',color:'black',fontSize:'25px'}} href="/horses"><button id="btn-primary">Click to See Horses</button></a>
                        <br/><br/><br/>
                    </div>
                    <div>
                        <div style={{float:'right',width:'50%'}}>
                            <img alt={'horse'} style={{width:'100%',padding:'50px'}} src={HorsePainting1}/>
                            <br/><br/>
                            <img alt={'horse'} style={{width:'100%',padding:'50px'}} src={HorsePainting2}/>
                            <br/><br/>
                            <img alt={'horse'} style={{width:'100%',padding:'50px'}} src={HorsePainting3}/>
                            <br/><br/><br/>
                        </div>
                        <div style={{float:'left',width:'50%'}}>
                            <img alt={'horse'} style={{width:'100%',padding:'50px'}} src={HorseReal1}/>
                            <br/><br/>
                            <img alt={'horse'} style={{width:'100%',padding:'50px'}} src={HorseReal2}/>
                            <br/><br/>
                            <img alt={'horse'} style={{width:'100%',padding:'50px'}} src={HorseReal3}/>
                            <br/><br/><br/>
                        </div>
                    </div>

                    {/* real/painting buildings */}
                    <div>
                        <h1>Building Portraits</h1>
                        <a style={{textDecoration:'none',color:'black',fontSize:'25px'}} href="/buildings"><button id="btn-primary">Click to See Buildings</button></a>
                        <br/><br/><br/>
                    </div>
                    <div>
                        <div style={{float:'right',width:'50%'}}>
                            <img alt={'building'} style={{width:'100%',padding:'50px'}} src={BuildingPainting1}/>
                            <br/><br/>
                            <img alt={'building'} style={{width:'100%',padding:'50px'}} src={BuildingPainting2}/>
                            <br/><br/>
                            <img alt={'building'} style={{width:'100%',padding:'50px'}} src={BuildingPainting3}/>
                            <br/><br/><br/>
                        </div>
                        <div style={{float:'left',width:'50%'}}>
                            <img alt={'building'} style={{width:'100%',padding:'50px'}} src={BuildingReal1}/>
                            <br/><br/>
                            <img alt={'building'} style={{width:'100%',padding:'50px'}} src={BuildingReal2}/>
                            <br/><br/>
                            <img alt={'building'} style={{width:'100%',padding:'50px'}} src={BuildingReal3}/>
                            <br/><br/><br/>
                        </div>
                    </div>

                    {/* real/painting cats */}
                    <div>
                        <h1>Cat Portraits</h1>
                        <a style={{textDecoration:'none',color:'black',fontSize:'25px'}} href="/cats"><button id="btn-primary">Click to See Cats</button></a>
                        <br/><br/><br/>
                    </div>
                    <div>
                        <div style={{float:'right',width:'50%'}}>
                            <img alt={'cat'} style={{width:'100%',padding:'50px'}} src={CatPainting3}/>
                            <br/><br/>
                            <img alt={'cat'} style={{width:'100%',padding:'50px'}} src={CatPainting2}/>
                            <br/><br/>
                            <img alt={'cat'} style={{width:'100%',padding:'50px'}} src={CatPainting1}/>
                            <br/><br/><br/>
                        </div>
                        <div style={{float:'left',width:'50%'}}>
                            <img alt={'cat'} style={{width:'100%',padding:'50px'}} src={CatReal3}/>
                            <br/><br/>
                            <img alt={'cat'} style={{width:'100%',padding:'50px'}} src={CatReal2}/>
                            <br/><br/>
                            <img alt={'cat'} style={{width:'100%',padding:'50px'}} src={CatReal1}/>
                            <br/><br/><br/>
                        </div>
                    </div>
                </div>








            </div>
        )
    }
}

export default PaintingSection;